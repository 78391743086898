import React from 'react';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import { Plan } from '@wix/seating-viewer';
import { SeatingPlan } from '@wix/ambassador-seating-v1-seating-plan/types';
import { OnPlaceClick, PlacesStock, useViewBox, MAX_ZOOM } from '@wix/seating-common-components';
import s from './widget.scss';
import { Controls } from './controls';

export type ControllerProps = {
  plan: SeatingPlan;
  onSeatClick: OnPlaceClick;
  selectedSeatIds: string[];
  activeSeatId: string;
  placesStock: PlacesStock;
};

const ZOOM_STEP = 0.2;

const Widget = ({
  plan,
  onSeatClick = () => null,
  activeSeatId = '',
  selectedSeatIds = [],
  placesStock = {},
  host: {
    dimensions: { height },
  },
}: WidgetProps<ControllerProps>) => {
  const svgContainer = React.useRef<HTMLDivElement>(null);
  const { isMobile: mobile } = useEnvironment();
  const {
    ready,
    viewBox,
    onPointerDown,
    onPointerUp,
    onPointerLeave,
    onPointerMove,
    cursor,
    changeSvgViewBox,
    minZoom,
    zoom,
  } = useViewBox(plan, mobile, svgContainer);

  if (!plan) {
    return <div data-hook="seating-empty-state" style={{ height: 50 }}></div>;
  }

  const handleZoomIn = () => {
    changeSvgViewBox({ deltaScale: ZOOM_STEP });
  };

  const handleZoomOut = () => {
    changeSvgViewBox({ deltaScale: -ZOOM_STEP });
  };

  return (
    <div
      data-hook="seating-widget-wrapper"
      className={s.container}
      ref={svgContainer}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerLeave={onPointerLeave}
      onPointerMove={onPointerMove}
      style={{ cursor, height }}
    >
      {ready ? (
        <Plan
          plan={plan}
          currentlyClickedPlaceId={activeSeatId}
          onPlaceClick={onSeatClick}
          selectedPlaceIds={selectedSeatIds}
          svgViewBox={viewBox}
          placesStock={placesStock}
        />
      ) : null}
      <Controls
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
        zoomInDisabled={zoom === MAX_ZOOM}
        zoomOutDisabled={zoom === minZoom}
      />
    </div>
  );
};

export default Widget;
