import React from 'react';
import Plus from 'wix-ui-icons-common/on-stage/Plus';
import Minus from 'wix-ui-icons-common/on-stage/Minus';
import { IconButton } from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import classNames from 'classnames';
import { classes } from './controls.st.css';
import { ControlsProps } from './index';

export const Controls = ({ zoomOutDisabled, zoomInDisabled, onZoomIn, onZoomOut }: ControlsProps) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  return (
    <div className={classNames(classes.controls, { [classes.desktopControls]: !isMobile })}>
      <IconButton
        aria-label={t('seating_zoomIn')}
        className={classes.control}
        disabled={zoomInDisabled}
        onClick={zoomInDisabled ? undefined : onZoomIn}
        icon={<Plus />}
      />
      <IconButton
        aria-label={t('seating_zoomOut')}
        className={classes.control}
        disabled={zoomOutDisabled}
        onClick={zoomOutDisabled ? undefined : onZoomOut}
        icon={<Minus />}
      />
    </div>
  );
};
